import { forwardRef, ReactElement } from 'react'
import { Textarea as BaseTextarea, TextareaProps as BaseTextareaProps } from '@/components/ui/textarea'
import { cls } from '@/utils'

interface TextareaProps extends BaseTextareaProps {
  suffix?: ReactElement
  suffixClassName?: string
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, suffix, suffixClassName, ...props }, ref) => {
    const textareaCls =
      'rounded-lg md:rounded-2xl py-[14px] px-3 min-h-12 max-h-30 md:max-h-60 text-body-lg leading-6 text-text shadow-none appearance-none overflow-auto no-scrollbar border border-b-2 border-border focus:border-border-hover'

    return (
      <div className='relative flex items-center w-full' aria-label='Creation Input Textarea'>
        <div className='grid items-stretch [&>*]:[grid-area:2/1] flex-1' aria-label='text-area'>
          <BaseTextarea
            ref={ref}
            rows={1}
            className={cls('resize-none bg-transparent', textareaCls, className)}
            {...props}
          />
          <span
            aria-hidden
            aria-label='input value'
            className={cls('invisible whitespace-pre-wrap break-all md:pr-[172px]', textareaCls)}
          >
            {props.value}
          </span>
        </div>
        <div className={cls('absolute bottom-4 right-3', suffixClassName)}>{suffix}</div>
      </div>
    )
  },
)

Textarea.displayName = 'Textarea'
