'use client'

import { DEFAULT_AVATAR, MAGIC_DELIMITER } from '@/constants'
import useBrowser from '@/hooks/useBrowser'
import { PoNVoid } from '@/types'
import { calculateAspectRatio, cls, isMobile, openNewTab, preventDefaultAndStopPropagation, whisper } from '@/utils'
import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react'
import Video, { VideoProps } from '../video'
import { useInView } from 'react-intersection-observer'
import { useRouter } from 'next/navigation'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import SocialPostBanner from '../gallery/social-post-banner'
import Avatar from '../avatar'
import WorkActions from './actions'
import Prize from '../prize'
import Image from '../image'

export interface WorkProps {
  className?: string
  data: haiper.Work
  commits?: haiper.Commits
  commentCount?: number
  onShow?: (data: haiper.Work) => PoNVoid
}

export default function Work({ data, className, commits, commentCount, onShow }: WorkProps) {
  const router = useRouter()
  const browser = useBrowser()
  const [hovering, setHovering] = useState(false)
  const { isBelowMd } = useBreakpoint('md')
  const socialPost = useMemo(() => data?.social_posts?.[0] ?? null, [data])
  const showPrize = !!data?.prize

  const handleMouseEnter = useCallback(() => {
    setHovering(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHovering(false)
  }, [])

  const { ref: contentRef, inView } = useInView({
    threshold: 0.75,
    triggerOnce: true,
    onChange(inView, entry) {
      if (inView) {
        onShow?.(data)
      }
    },
  })

  const coverAspectStyle: CSSProperties = useMemo(() => {
    const { width, height } = data?.spec ?? {}
    const aspectRatio = calculateAspectRatio(width, height)
    return {
      aspectRatio,
    }
  }, [data?.spec])

  const handleClick = useCallback(() => {
    switch (data?.work_type) {
      case 'creation_video':
        router.push(`/creation/${data.work_id}?type=output`)
        break
      case 'creation_image':
        router.push(`/creation/${data.work_id}?type=output`)
        break
      case 'spotlight':
        router.push(`/spotlight/${data.work_id}`)
        break
      default:
        break
    }
  }, [data, router])

  const handleGotoVideoDetail = useCallback(() => {
    if (data?.work_type === 'creation_video') {
      router.push(`/creation/${data.work_id}?type=output`)
    }
  }, [data, router])

  const creationsControlsProps: VideoProps['controlsProps'] = useMemo(() => {
    return {
      hidePlayPause: true,
      hideProgress: true,
      hideResolutions: true,
      // hideVolume: true,
      className: 'pl-[22px]',
      onFullscreen: handleGotoVideoDetail,
    }
  }, [handleGotoVideoDetail])

  const footerRef = useRef<HTMLDivElement>(null)

  const handleFooterClick = useCallback(
    (e: any) => {
      preventDefaultAndStopPropagation(e)
      if (e.target === footerRef.current || e.target?.parentElement?.parentElement === footerRef.current) {
        handleClick()
      }
    },
    [handleClick],
  )

  const handleGotoAuthorProfile = useCallback(
    (e: any) => {
      preventDefaultAndStopPropagation(e)

      // open in new tab
      const url = `/profile/${data?.user_id}`
      openNewTab(url)
    },
    [data],
  )

  const renderMain = () => {
    if (data?.work_type !== 'creation_image') {
      return (
        <Video
          // blurBg
          playOnHover
          playsInline
          hasAudioTrack={data?.spec?.has_audio}
          // controls
          controlsProps={creationsControlsProps}
          className={cls('rounded-[6px] border border-border object-cover object-top h-full')}
          roundedClassName={cls('rounded-sm sm:rounded-md')}
          maskClassName='border'
          playClassName='pointer-events-none absolute top-2 left-2 size-4'
          canPlay={hovering || isMobile()}
          autoPlay={hovering && !isBelowMd}
          src={data?.media_url}
          poster={data?.thumbnail_url}
          preload={isBelowMd || !inView ? 'none' : 'auto'}
          onClick={handleClick as any}
        />
      )
    } else {
      return (
        <Image
          className={cls('size-full m-auto absolute', 'object-cover object-top rounded-md overflow-hidden')}
          src={data?.media_url}
          alt=''
          style={coverAspectStyle}
          onClick={handleClick}
        />
      )
    }
  }

  return (
    <div
      key={[data.work_type, data.work_id].join(MAGIC_DELIMITER)}
      className={cls(
        '@container pointer-events-auto flex flex-col justify-center items-center h-auto max-h-full rounded-lg gap-1 cursor-pointer',
        browser?.name !== 'safari' ? (hovering ? 'z-[1]' : 'z-0') : '',
        className,
      )}
      style={coverAspectStyle}
      data-testid={`work-item-${data.work_type}-${data.work_id}`}
      data-spec-width={data?.spec?.width}
      data-spec-height={data?.spec?.height}
      aria-label='gallery-item'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleClick()
        }
      }}
    >
      <div
        ref={contentRef}
        className={cls(
          'group/video select-none size-full max-w-full max-h-full relative flex justify-center items-center rounded-md overflow-hidden bg-surface-on-video/70 backdrop-blur-md',
        )}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleClick()
          }
        }}
      >
        {renderMain()}
        {socialPost && <SocialPostBanner post={socialPost} />}
      </div>
      <div
        ref={footerRef}
        className={cls('w-full flex justify-between text-icon items-center h-9 @container cursor-pointer')}
        aria-label='video-footer'
        onClick={handleFooterClick}
      >
        <div
          className={cls(
            '@sm:mr-1 @md:mr-5 cursor-pointer flex gap-2.5 items-center p-0.5 pr-1 hover:bg-surface-hover rounded-md w-0 flex-1 max-w-max',
          )}
          aria-label='author info'
          onClick={handleGotoAuthorProfile}
        >
          <Avatar src={data?.avatar || DEFAULT_AVATAR} />
          <div className='text-body-sm whitespace-nowrap truncate' aria-label='author name'>
            {data?.username ?? ''}
          </div>
        </div>
        <WorkActions className='' data={data} commits={commits} commentCount={commentCount} />
      </div>
      {showPrize && <Prize className='absolute top-2 left-2 pointer-events-none z-10' prize={data.prize} />}
    </div>
  )
}
