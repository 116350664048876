import { Suggestion } from '@/types'
import useDefaultRecommendStyles from './useDefaultRecommendStyles'
import { useMemo } from 'react'

const useSuggestions = () => {
  const { data: recommendStyles } = useDefaultRecommendStyles()
  const suggestions: Suggestion[] = useMemo(() => {
    return recommendStyles ?? []
  }, [recommendStyles])

  return suggestions
}

export default useSuggestions
