import type { Pagination, PaginationQuery } from '@/types'
import { rest } from '@/utils'

export interface GetPublishedQuery {
  event_id?: string
  work_type?: haiper.MarketingEventWorkType
  work_types?: string
  sort_by?: 'rank' | 'time'
  social_post_exist?: boolean
}

export const getPublished = async (params: PaginationQuery<GetPublishedQuery>): Promise<Pagination<haiper.Work>> => {
  const res = await rest.get('/v1/published', { params })
  return res.data
}
