import { PoNVoid } from '@/types'
import { cls } from '@/utils'
import { ReactElement, useCallback } from 'react'
import InfiniteItem from './item'
import Loading from '../loading'
import Empty from '../empty'
import MagicWaterfall from '../waterfall/magic'

export interface InfiniteProps<T = any> {
  dataSource: T[] | null
  className?: string
  loading?: boolean
  loadMore: () => PoNVoid
  hasMore?: boolean
  itemRenderer: (item: T, index: number, onShow: () => void) => ReactElement
  rowKey?: string
  cacheMargin?: number
  customIntersectionObserver?: boolean
  waterfall?: boolean
}

export default function Infinite<T = any>({
  className,
  itemRenderer,
  dataSource,
  loading,
  rowKey,
  customIntersectionObserver = false,
  hasMore,
  waterfall,
  loadMore,
  cacheMargin = 5,
}: InfiniteProps<T>) {
  const Container = waterfall ? MagicWaterfall : 'div'

  const checkLoadMore = useCallback(
    (data: T) => {
      const index = (dataSource ?? []).findIndex((item) => item === data)
      const shouldLoadMore = index >= (dataSource ?? []).length - cacheMargin
      if (shouldLoadMore) {
        loadMore()
      }
    },
    [loadMore, dataSource, cacheMargin],
  )

  const showLoading = loading && !dataSource?.length

  if (showLoading) {
    return (
      <div className='size-full flex items-center justify-center h-100'>
        <Loading />
      </div>
    )
  }

  return (
    <Container
      className={cls(
        'relative flex flex-col size-full overflow-y-auto no-scrollbar',
        waterfall && 'grid grid-cols-1 tablet:grid-cols-2 widescreen:grid-cols-3 gap-4 md:gap-6',
        className,
      )}
    >
      {dataSource?.length || hasMore ? (
        dataSource?.map((item, index) => {
          const key = (item as any)?.[rowKey as any] ?? index
          const children = itemRenderer(item, index, () => checkLoadMore(item))
          if (customIntersectionObserver) {
            return children
          }
          return (
            <InfiniteItem key={key} onShow={() => checkLoadMore(item)}>
              {children}
            </InfiniteItem>
          )
        })
      ) : (
        <div className='absolute inset-0 size-full flex items-center justify-center'>
          <Empty />
        </div>
      )}
    </Container>
  )
}
