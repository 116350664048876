import { calculateAspectRatio, cls, noop, whisper } from '@/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Infinite from '@/components/infinite'
import Work from '@/components/work'
import Loading from '@/components/loading'
import { IntersectionGuard } from '@haiper/react-components'
import { useWindowSize } from 'usehooks-ts'
import useGridColumns from '@/hooks/useGridColumns'
import useGapSize from '@/hooks/useGapSize'
import Skeleton from '@/components/skeleton'
import useExpandedOutputs from '@/hooks/useExpandedOutputs'
import useExpandedSpotlights from '@/hooks/useExpandedSpotlights'

export interface WorksProps {
  className?: string
  data: haiper.Work[]
  loading?: boolean
  onItemShow?: (item: haiper.Work) => void
}

export default function Works({ className, data, onItemShow, loading }: WorksProps) {
  const hasMore = true // BE doesn't return the total count, so we always assume there are more
  const innerRef = useRef<HTMLDivElement | null>(null)

  const latestGalleryItemsRef = useRef<haiper.Work[]>([])

  const outputIds = useMemo(() => {
    return (
      data
        ?.filter((item) => ['creation_video', 'creation_image'].includes(item.work_type ?? ''))
        .map((item) => item.work_id) ?? []
    )
  }, [data])

  const spotlightIds = useMemo(() => {
    return data?.filter((item) => item.work_type === 'spotlight').map((item) => item.work_id) ?? []
  }, [data])

  const { data: outputs } = useExpandedOutputs(outputIds)
  const { data: spotlights } = useExpandedSpotlights(spotlightIds)

  useEffect(() => {
    if (!loading) {
      latestGalleryItemsRef.current = data
    }
  }, [loading, data])

  const gridClassname = cls('grid grid-cols-1 tablet:grid-cols-2 md:grid-cols-3 desktop:grid-cols-4 gap-6', className)

  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const gapSize = useGapSize(gridClassname)

  const columnCount = useGridColumns(gridClassname)
  const calculateItemWidth = useCallback(() => {
    const gridWidth = innerRef.current?.clientWidth ?? 0
    const result = Math.max(0, Math.floor((gridWidth - (columnCount - 1) * gapSize) / columnCount))

    return result
  }, [columnCount, gapSize])

  const [itemWidth, setItemWidth] = useState(calculateItemWidth())
  const autoUpdateSizeTimerRef = useRef<number | null>(null)

  const updateItemSize = useCallback(() => {
    const newWidth = calculateItemWidth()
    setItemWidth(newWidth)

    if (newWidth <= 0) {
      if (autoUpdateSizeTimerRef.current) {
        cancelAnimationFrame(autoUpdateSizeTimerRef.current)
        autoUpdateSizeTimerRef.current = null
      }
      autoUpdateSizeTimerRef.current = requestAnimationFrame(updateItemSize)
    }
  }, [calculateItemWidth])

  useEffect(() => {
    requestAnimationFrame(updateItemSize)
  }, [windowWidth, windowHeight, updateItemSize])

  const handleItemShow = useCallback(
    (item: haiper.Work) => {
      onItemShow?.(item)
      requestAnimationFrame(updateItemSize)
    },
    [onItemShow, updateItemSize],
  )

  const calculateHeight = useCallback(
    (aspectRatio: number) => {
      const videoWidth = itemWidth
      const videoHeight = videoWidth / aspectRatio
      const footerAndGapSize = 32 + 8

      const height = videoHeight + footerAndGapSize
      return height
    },
    [itemWidth],
  )

  return (
    <div ref={innerRef} className={cls('w-full h-full')}>
      {data?.length ? (
        <Infinite
          waterfall
          dataSource={data}
          loadMore={noop}
          hasMore={hasMore}
          className={gridClassname}
          itemRenderer={(item: haiper.Work, index, onShow) => {
            const currentItemAspectRatio = calculateAspectRatio(item?.spec?.width, item?.spec?.height)
            const calculatedHeight = calculateHeight(currentItemAspectRatio)

            return (
              <IntersectionGuard
                key={`guard-${item?.work_type}-${item?.work_id}-${index}`}
                width={itemWidth || undefined}
                height={calculatedHeight || undefined}
                containerProps={{
                  className: cls('relative', !itemWidth && 'hidden'),
                }}
                fallback={
                  <div
                    className={cls(
                      'size-full rounded-lg border-2 border-b-4 border-solid border-border hover:border-border-hover active:border-border-hover p-2',
                      !itemWidth && 'hidden',
                      'border-none border-0 p-0',
                    )}
                  >
                    <Skeleton variant='video' className='size-full' />
                  </div>
                }
              >
                {() => {
                  const entity = item.work_type === 'spotlight' ? spotlights?.[item.work_id] : outputs?.[item.work_id]
                  return (
                    <Work
                      key={index}
                      data={item}
                      commits={entity?.commits}
                      commentCount={entity?.comment_num}
                      onShow={handleItemShow}
                    />
                  )
                }}
              </IntersectionGuard>
            )
          }}
        />
      ) : (
        <div className='size-full absolute inset-0 min-h-100 flex items-center justify-center'>
          <Loading />
        </div>
      )}
    </div>
  )
}
