import Link from '@/components/link'
import Button from '@/components/button'
import IconArrowTopCircle from '@haiper/icons-svg/icons/outline/arrow-top-circle.svg'

export default function SubmitWorkButton() {
  return (
    <Button variant='primary' className='h-10 px-3 py-2'>
      <Link href='/submit' className='flex items-center gap-1'>
        <IconArrowTopCircle clssName='size-6 shrink-0' />
        <span className='px-1'>Submit</span>
      </Link>
    </Button>
  )
}
