import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import Link from '@/components/link'
import Checkbox from '@/components/checkbox'
import { showCreationsAtom, showModeListAtom, creationInputAtom, subscriptionDialogContentAtom } from '@/atoms'
import useAmplitude from '@/hooks/useAmplitude'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { cls, fullTextSearch, stopPropagation } from '@/utils'
import BadgeNew from '@/components/badges/new'
import useActivePlan from '@/hooks/useActivePlan'
import BadgeUpdate from '@/components/badges/update'
import useCreationModes from '@/hooks/useCreationModes'
import Empty from '@/components/empty'

interface CreationModeListProps {
  className?: string
  listClassName?: string
  inPopover?: boolean
  inDialog?: boolean
  onSelectMode?: () => void
}

export function CreationModeList({
  className,
  listClassName,
  onSelectMode,
  inPopover,
  inDialog,
}: CreationModeListProps) {
  const showCreations = useAtomValue(showCreationsAtom)
  const setShowModeList = useSetAtom(showModeListAtom)
  const [{ mode }, setCreationInput] = useAtom(creationInputAtom)
  const { track } = useAmplitude()
  const { data: activePlan, loading: activePlanLoading } = useActivePlan()
  const isFreePlan = (activePlan?.is_free || !activePlan) && !activePlanLoading
  const setSubscriptionDialogContent = useSetAtom(subscriptionDialogContentAtom)
  const { data: creationModeOptions } = useCreationModes()

  const handleModeChange = useCallback(
    (option: (typeof creationModeOptions)[number]) => {
      if (isFreePlan && option.membersOnly) {
        setSubscriptionDialogContent({
          message: `Upgrade to Haiper Membership to access the ${option.name} feature.`,
        })
        return
      }

      const value = option.mode

      if (showCreations) {
        setShowModeList(false)
      }
      setCreationInput((prev) => ({
        ...prev,
        creation: undefined,
        mode: value,
        expanded: true,
        focusing: true,
      }))
      onSelectMode?.()
      track('input:creation:change-mode', {
        mode: value,
      })
    },
    [onSelectMode, setCreationInput, setShowModeList, showCreations, track, isFreePlan, setSubscriptionDialogContent],
  )

  const [keyword, setKeyword] = useState('')
  const [expandAllCategories, setExpandAllCategories] = useState(false)

  const filteredCreationModes = useMemo(() => {
    return (
      creationModeOptions
        ?.filter((e) => !e.hidden)
        .filter((e) => fullTextSearch(e.name, keyword) || fullTextSearch(e.mode, keyword)) ?? []
    )
  }, [creationModeOptions, keyword])

  const showCreationModes = filteredCreationModes.length > 0
  // const showTemplates = filteredTemplateCategories.length > 0
  const showTemplates = false
  const showEmpty = !showCreationModes && !showTemplates

  useEffect(() => {
    setExpandAllCategories(!!keyword.trim())
  }, [keyword])

  const cardStyle = 'flex p-3 pr-4 items-center justify-between rounded-lg border border-b-2 h-14 w-full'

  const sectionRef = useRef<HTMLDivElement>(null)

  return (
    <article
      className={cls('mx-auto flex flex-col justify-start w-full items-center', className)}
      data-component='creation-input'
      data-outside='false'
    >
      {inPopover || inDialog ? (
        <span
          className={cls(
            'tracking-32 w-full mb-2 text-center',
            inPopover ? 'text-left text-body-lg font-normal' : 'text-heading-lg font-bold',
          )}
        >
          Creation Mode
        </span>
      ) : (
        <header className='md:flex flex-col justify-center items-center mb-8'>
          <h1 className='text-center mb-0 sm:text-heading-4xl text-heading-2xl text-text font-bold font-sn'>
            Choose a template to create your own video
          </h1>
        </header>
      )}
      <section
        ref={sectionRef}
        className={cls('px-8', inPopover ? 'max-w-full px-0' : '', listClassName)}
        aria-label='content'
      >
        <div
          className={cls('pt-2 flex flex-col gap-4', showCreationModes ? 'flex' : 'hidden')}
          aria-label='basic ai tools'
        >
          {/* <span className='text-body-lg font-medium tracking-32'>Basic AI Tools</span> */}
          <div className={cls('grid grid-cols-1 gap-3 w-full')} aria-label='Creation Mode'>
            {creationModeOptions
              .filter((e) => !e.hidden)
              .filter((e) => fullTextSearch(e.name, keyword) || fullTextSearch(e.mode, keyword))
              .map((option) => {
                const { Icon, iconClassName } = option
                const needUpgrade = option.membersOnly && isFreePlan
                const isActive = mode === option.mode

                return (
                  <div
                    key={option.mode}
                    className={cls(
                      cardStyle,
                      'relative cursor-pointer bg-white/5 border border-b-2 border-solid border-border box-border hover:border-border-hover active:border-border-hover',
                      'hover:bg-surface-hover hover:border-border-hover',
                      isActive ? 'cursor-default border-border-active hover:border-border-active bg-surface' : '',
                      !option.available
                        ? 'hover:bg-white/5 active:bg-white/5 shadow-none hover:border-border cursor-not-allowed'
                        : '',
                    )}
                    aria-label='creation mode item'
                    onClick={(e: any) => {
                      e?.preventDefault?.()
                      e?.stopPropagation?.()
                      if (option.available) {
                        handleModeChange(option)
                      }
                    }}
                  >
                    <div
                      className={cls(
                        'opacity-0 size-0 absolute',
                        option.available ? 'pointer-events-auto' : 'pointer-events-none',
                      )}
                    />
                    <div className='shrink-0 flex flex-row items-center w-full gap-1'>
                      <div
                        className={cls(
                          'size-8 p-1 rounded-full shrink-0 text-icon-on-color',
                          needUpgrade ? 'opacity-50' : '',
                          iconClassName,
                        )}
                      >
                        <Icon className={cls(!option.available ? 'opacity-50' : '')} alt={option.name} />
                      </div>
                      <div>
                        <div
                          className={cls(
                            'truncate text-body-md font-medium px-1',
                            needUpgrade ? 'text-text-disabled' : '',
                          )}
                        >
                          {option.name}
                        </div>
                        {option.newFeature ? (
                          <div className='flex items-center text-body-sm text-text-interactive px-1'>
                            {option.newFeature}
                          </div>
                        ) : null}
                        {option.available ? null : (
                          <div className='text-text-subdued text-body-md ml-auto'>Coming soon</div>
                        )}
                      </div>
                      {option.membersOnly ? (
                        <div className='flex items-center gap-1 text-body-sm px-1 ml-auto mr-4'>
                          <Link
                            href='/membership'
                            className='leading-5 tracking-15 font-bold text-text-interactive'
                            onClick={stopPropagation as any}
                          >
                            Members
                          </Link>
                          <span className=''>only</span>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={cls(
                        'absolute right-2 inset-y-2 flex flex-col items-end gap-0.5',
                        option.isNew || option.isUpdated ? 'justify-start' : 'justify-center',
                      )}
                    >
                      {option.isNew ? <BadgeNew className='' /> : null}
                      {option.isUpdated ? <BadgeUpdate className='' /> : null}
                      {isActive ? <Checkbox checked className='mr-2' /> : null}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div className={cls('pt-4 flex flex-col gap-4', showTemplates ? 'flex' : 'hidden')} aria-label='templates'>
          <span className='text-body-lg font-medium tracking-32'>Templates</span>
          <div className='flex flex-col w-full'></div>
        </div>
        {showEmpty && <Empty className='w-full py-10' />}
      </section>
    </article>
  )
}
