import { useState } from 'react'
import { cls } from '@/utils'
import { CreationModeList } from './list'
import { ButtonCreationMode, ButtonCreationModeProps } from './button'
import Popover from '@/components/popover'

export default function CreationModePopover({ ...props }: ButtonCreationModeProps) {
  const [open, setOpen] = useState(false)
  return (
    <Popover
      open={open}
      trigger={<ButtonCreationMode {...props} />}
      // className='w-[332px] md:w-[332px] max-w-[332px] md:max-w-[332px] md:min-w-[332px] h-auto max-h-[80vh] md:max-h-[calc(min(80vh,780px))] border border-border border-solid p-2 px-4 md:p-6 rounded-md'
      // closeClassName='absolute top-4 right-4 md:top-4 md:right-4 border-0 border-none'
      onOpenChange={setOpen}
    >
      <CreationModeList
        inPopover
        className={cls('h-full overflow-y-hidden')}
        listClassName='min-h-0 overflow-y-auto no-scrollbar px-0 md:py-0 w-full'
        onSelectMode={() => setOpen(false)}
      />
    </Popover>
  )
}
