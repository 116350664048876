import { GenerationSetting, CreationModeEnum, CreationModeEnumOrUsecaseEnum, ModelVersion } from '@/types'
import { DEFAULT_ASPECT_RATIO, DEFAULT_IMAGE_RESOLUTION, DEFAULT_RESOLUTION } from '@/constants'
import useCurrentCreationMode from './useCurrentCreationMode'
import { useMemo } from 'react'
import useActivePlan from './useActivePlan'
import useModelVersion from './useModelVersion'

const DEFAULT_REPAINT_STRENGTH = 1

export default function useDefaultSettings() {
  const { data: currentCreationMode, loading: currentCreationModeLoading } = useCurrentCreationMode()
  const mode = currentCreationMode?.mode ?? ''
  const { data: activePlan, loading: activePlanLoading } = useActivePlan()
  const isFreePlan = (activePlan?.is_free || !activePlan) && !activePlanLoading
  const { version: modelVersion } = useModelVersion()
  const model2Active = modelVersion === ModelVersion.TWO

  // const model2DefaultDuration = isFreePlan ? 4 : 6
  const model2DefaultDuration = isFreePlan ? 4 : 4

  const defaultSettingsMap: Record<CreationModeEnumOrUsecaseEnum, Partial<GenerationSetting>> = useMemo(() => {
    return {
      [CreationModeEnum.CreateImg]: {
        seed: -1,
        aspect_ratio: DEFAULT_ASPECT_RATIO,
        resolution: DEFAULT_IMAGE_RESOLUTION,
        // fps: 8,
      },
      [CreationModeEnum.CreateHD]: {
        seed: -1,
        duration: model2Active ? model2DefaultDuration : 4,
        aspect_ratio: DEFAULT_ASPECT_RATIO,
        resolution: DEFAULT_RESOLUTION,
        camera_movement: 'none',
        // gen_mode: model2Active && !isFreePlan ? 'enhanced' : 'smooth',
        // fps: 8,
        // gen_mode: model2Active ? 'smooth' : undefined,
      },
      [CreationModeEnum.AnimateHD]: {
        seed: -1,
        duration: model2Active ? model2DefaultDuration : 4,
        resolution: DEFAULT_RESOLUTION,
        camera_movement: 'none',
        // fps: 8,
      },
      [CreationModeEnum.Repaint]: {
        seed: -1,
        resolution: DEFAULT_RESOLUTION,
        strength: DEFAULT_REPAINT_STRENGTH,
      },
      [CreationModeEnum.Extend]: {
        seed: -1,
        resolution: 720,
        strength: DEFAULT_REPAINT_STRENGTH,
      },
      [CreationModeEnum.Upscale]: {
        seed: -1,
        resolution: 720,
        strength: DEFAULT_REPAINT_STRENGTH,
      },
    }
  }, [model2Active, model2DefaultDuration])

  const loading = currentCreationModeLoading

  const defaultSettings = useMemo(() => {
    return defaultSettingsMap[mode] ?? {}
  }, [mode, defaultSettingsMap])

  return {
    data: defaultSettings,
    loading,
  }
}
