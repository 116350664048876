import { commentCacheAtom } from '@/atoms'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'

const useCommentCache = (id: string = '', autoClear?: boolean) => {
  const [commentCache, setCommentCache] = useAtom(commentCacheAtom)

  const clearAll = useCallback(
    (prefix?: string) => {
      if (!prefix) {
        setCommentCache({})
      } else {
        setCommentCache((prev) => {
          const newState = Object.keys(prev).reduce(
            (acc, key) => {
              if (!key.startsWith(prefix)) {
                acc[key] = prev[key]
              }
              return acc
            },
            {} as Record<string, number>,
          )
          return newState
        })
      }
    },
    [setCommentCache],
  )

  const clear = useCallback(() => {
    if (!id) {
      return
    }
    setCommentCache((prev) => {
      const newState = { ...prev }
      delete newState[id]
      return newState
    })
  }, [setCommentCache, id])

  const count = useMemo(() => (id ? commentCache?.[id] ?? null : null), [commentCache, id])

  useEffect(() => {
    if (autoClear) {
      return clear
    }
  }, [clear, autoClear])

  return { commentcache: commentCache, count, clearAll, clear }
}

export const withPrefix = (id: string, prefix: string) => {
  return id ? `${prefix}${id}` : id
}

export const CreationOutputCommentCachePrefix = 'creation-output:'
export const SpotlightCommentCachePrefix = 'spotlight:'

export const useCreationOutputCommentcache = (id: string, autoClear?: boolean) =>
  useCommentCache(withPrefix(id, CreationOutputCommentCachePrefix), autoClear)

export const useSpotlightCommentcache = (id: string, autoClear?: boolean) =>
  useCommentCache(withPrefix(id, SpotlightCommentCachePrefix), autoClear)

export default useCommentCache
