import { getPublished } from '@/service/submission.service'
import useService from '@banyudu/use-service'
import useInfinite from './useInfinite'
import useAuthedSkip from './useAuthedSkip'

const usePublished = useService(getPublished, useAuthedSkip(), {
  keepPreviousData: true,
})

export const useInfinitePublished = useInfinite(usePublished, {
  rowKey: ['work_type', 'work_id'],
})

export default usePublished
