'use client'

import { CreationMode } from '@/types'
import useCreationModes from './useCreationModes'
import { useAtom } from 'jotai'
import { creationInputAtom } from '@/atoms'
import { useEffect, useMemo } from 'react'

export default function useCurrentCreationMode(): {
  data: CreationMode | null
  loading: boolean
} {
  const { data: creationModeOptions } = useCreationModes()
  const [creationInput, setCreationInput] = useAtom(creationInputAtom)
  const result = useMemo(() => {
    return creationModeOptions.find((option) => option.mode === creationInput?.mode)
  }, [creationInput, creationModeOptions])

  useEffect(() => {
    if (!result && creationModeOptions.length) {
      setCreationInput((prev) => {
        return {
          ...prev,
          mode: creationModeOptions[0].mode,
        }
      })
    }
  }, [result, creationModeOptions, setCreationInput])

  return {
    data: result ?? null,
    loading: false,
  }
}
