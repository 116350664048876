'use client'

import CompassSolid from '@haiper/icons-svg/icons/solid/compass-circle.svg'
import CompassOutline from '@haiper/icons-svg/icons/outline/compass-circle.svg'

import PeopleOutline from '@haiper/icons-svg/icons/outline/people.svg'
import PeopleSolid from '@haiper/icons-svg/icons/solid/people.svg'

import LoginOutline from '@haiper/icons-svg/icons/outline/login.svg'
import LoginSolid from '@haiper/icons-svg/icons/solid/login.svg'

import LightOutine from '@haiper/icons-svg/icons/outline/light-bulb.svg'
import LightSolid from '@haiper/icons-svg/icons/solid/light-bulb.svg'

import CupChampionWinOutline from '@haiper/icons-svg/icons/outline/cup-champion-win.svg'
import CupChampionWinSolid from '@haiper/icons-svg/icons/solid/cup-champion-win.svg'

import PlusOutline from '@haiper/icons-svg/icons/outline/plus-large.svg'
import PlusSolid from '@haiper/icons-svg/icons/solid/plus-large.svg'

import BellOutline from '@haiper/icons-svg/icons/outline/bell.svg'
import BellSolid from '@haiper/icons-svg/icons/solid/bell.svg'

import AlbumsOutline from '@haiper/icons-svg/icons/outline/albums.svg'
import AlbumsSolid from '@haiper/icons-svg/icons/solid/albums.svg'

import useHasSignIn from './useHasSignIn'
import useAuth0Auth from './useAuth0Auth'
import { NavLink } from '@/types'
import { useMemo } from 'react'
import { useCachedMyProfile } from './useMyProfile'

import IconBadgeVerifiedOutline from '@haiper/icons-svg/icons/outline/badge-verified.svg'
import IconBadgeVerifiedSolid from '@haiper/icons-svg/icons/solid/badge-verified.svg'
import { useBreakpoint } from './useBreakPoint'
import { useUnreadNotificationsCount } from './useNotifications'
import { cls } from '@/utils'
import { usePathname } from 'next/navigation'
import useOngoingEvents from './useOngoingEvents'
import { useAtomValue } from 'jotai'
import { readedEventsAtom } from '@/atoms'

const useNavLinks = (): { data: NavLink[]; loading: boolean } => {
  const isLogin = useHasSignIn()
  const loading = false
  const { login } = useAuth0Auth()
  const { data: profile } = useCachedMyProfile()
  const { isBelowMd } = useBreakpoint('md')
  const { data: ongoingEvents } = useOngoingEvents(48 * 60 * 60 * 1000) // 48 hours
  const readedEventIds = useAtomValue(readedEventsAtom)

  const unreadedEvents = useMemo(() => {
    return ongoingEvents?.filter((event) => !readedEventIds.includes(event.event_id))
  }, [ongoingEvents, readedEventIds])

  const pathname = usePathname()

  // const showNewBadgeForEvents = unreadedEvents?.length > 0 && pathname !== '/events'
  const showNewBadgeForEvents = unreadedEvents?.length > 0

  const creationLink: NavLink = useMemo(() => {
    return {
      id: 'nav-link-create',
      href: '/creations',
      key: 'creations',
      icon: PlusOutline,
      activeIcon: PlusSolid,
      className:
        'bg-surface-primary text-text-on-color hover:text-text-on-color hover:bg-surface-primary-hover active:bg-surface-primary-active',
      iconClassName: 'text-icon-on-color',
      labelClassName: 'text-text-on-color hover:text-text-on-color',
      label: 'Create',
    }
  }, [])

  const createLinks: NavLink[] = useMemo(() => {
    return loading
      ? []
      : isLogin
        ? [creationLink]
        : [
            {
              href: '#',
              key: 'signin',
              icon: LoginOutline,
              activeIcon: LoginSolid,
              label: 'Login to create',
              className:
                'bg-surface-primary text-text-on-color hover:text-text-on-color hover:bg-surface-primary-hover active:bg-surface-primary-active',
              iconClassName: 'text-icon-on-color',
              labelClassName: 'text-text-on-color hover:text-text-on-color',
              onClick: login,
            },
          ]
  }, [isLogin, loading, login, creationLink])

  const unreadNotificationsCount = useUnreadNotificationsCount()

  const notificationLinks: NavLink[] = useMemo(() => {
    return [
      {
        href: '/notifications',
        key: 'notifications',
        icon: BellOutline,
        activeIcon: BellSolid,
        label: 'Notifications',
        badge:
          unreadNotificationsCount > 0 && pathname !== '/notifications' ? ( // hide badge when on notifications page #PROD-752
            <div className='absolute inset-y-0 right-0 widescreen:right-3 flex items-start widescreen:items-center'>
              <span
                className={cls(
                  'w-5 min-w-max h-5 rounded-sm bg-surface-critical-strong text-text-on-color shrink-0 flex items-center justify-center text-body-sm font-medium px-2 py-1',
                )}
              >
                {unreadNotificationsCount > 99 ? '99+' : unreadNotificationsCount}
              </span>
            </div>
          ) : undefined,
      },
    ]
  }, [unreadNotificationsCount, pathname])

  const eventsLinks: NavLink[] = useMemo(() => {
    return [
      {
        href: '/events',
        key: 'events',
        icon: CupChampionWinOutline,
        activeIcon: CupChampionWinSolid,
        label: 'Events',
        badge: showNewBadgeForEvents ? (
          <div className='z-20 pointer-events-none absolute right-0 widescreen:right-3 top-2 widescreen:top-[unset] hidden widescreen:block'>
            <div className='bg-surface-critical-strong text-text-on-color px-1 h-5 rounded-sm tracking-15 flex items-center justify-center text-body-md font-bold'>
              <span className='px-1 hidden widescreen:block'>NEW</span>
            </div>
          </div>
        ) : undefined,
      },
    ]
  }, [showNewBadgeForEvents])

  const result = useMemo(() => {
    return {
      data: [
        {
          href: '/',
          key: 'explore',
          icon: CompassOutline,
          activeIcon: CompassSolid,
          label: 'Home',
        },
        // ...(isBelowMd
        //   ? []
        //   : [
        //       {
        //         href: '/templates',
        //         key: 'templates-home',
        //         icon: AlbumsOutline,
        //         activeIcon: AlbumsSolid,
        //         label: 'Templates',
        //       },
        //     ]),
        {
          href: '/spotlight',
          key: 'spotlight',
          icon: LightOutine,
          activeIcon: LightSolid,
          label: 'Spotlight',
        },
        ...(isBelowMd ? [] : eventsLinks),
        ...(isBelowMd ? [] : notificationLinks),
        ...(isBelowMd ? createLinks : []),
        {
          href: profile ? `/profile/${profile?.user_id}` : '#',
          key: 'profile',
          icon: PeopleOutline,
          activeIcon: PeopleSolid,
          label: 'Profile',
        },
        ...(isBelowMd
          ? []
          : [
              {
                href: '/membership',
                key: 'membership',
                icon: IconBadgeVerifiedOutline,
                activeIcon: IconBadgeVerifiedSolid,
                label: 'Membership',
                // label: (
                //   <div className='flex gap-1 items-center'>
                //     <span>Membership</span>
                //     <div className='h-6 flex items-start' aria-label='new badge'>
                //       <div className='h-4 rounded-[5px] border border-solid border-border px-1 py-0.5 text-text-interactive text-xs font-bold flex items-center justify-center'>
                //         NEW
                //       </div>
                //     </div>
                //   </div>
                // ),
              },
            ]),
        ...(isBelowMd ? [] : createLinks),
      ],
      loading,
    }
  }, [isBelowMd, loading, profile, createLinks, eventsLinks, notificationLinks])

  return result
}

export default useNavLinks
